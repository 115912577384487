@charset "utf-8";
/* ============================== */
/*
 - Title: Learn Theme - Landing Page Template
 */
/* ============================== */

/* ---------------------------------------------------------------------- */
/*  Import styles
 /* ---------------------------------------------------------------------- */

/* Bootstrap.css  - include resetes ( Media querys, grid-responsive,layout).*/
@import url("./files/bootstrap/bootstrap.css");

/*Google Fonts*/
@import url(http://fonts.googleapis.com/css?family=Raleway:400,700,800,900,600,500,300);
@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,700);

/*animate
@import url("./files/animate-custom.css");
*/
/*fancy*/
/*@import url("./js/fancy/jquery.fancybox.css");*/

/* helpers */
/*@import url("./files/helpers.css");*/

/* animate 
@import url("./files/animate-custom.css");

@import url("./files/media-queries.css");
*/

html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}

/* Reset Styles --------------------------------------------------------*/
html {
  font-size: 100%;
  width: 100%;
  overflow-x: hidden; 
}
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 62.5%;
  margin-top: 0px;
  min-width: 318px;
  color: #34495e;
}
a{
  -webkit-transition: background-color 0.6s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
           -moz-transition: background-color 0.6s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
            -ms-transition: background-color 0.6s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
             -o-transition: background-color 0.6s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
                transition: background-color 0.6s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
}
a, a:hover, a:active {
  outline: 0 !important;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
}

p {
  font-size: 14px;
}

.sos-header {
  margin-top: 10px;
  position: relative;
}

.navbar-brand {
  margin-bottom: 10px;
}

.header-login {
  float: right;
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
}

.header-login button {
  background-color: #4398e9;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 9px 25px 9px 25px;
}

.intro-style {
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}

#logo span {
    /*color: #000;
    font-size: 26px;
    font-family: 'Raleway', sans-serif;*/
    color: #011f53;
    font-size: 42px;
    font-family: 'Raleway', sans-serif;
    position: absolute;
    top: 29px;
    font-weight: 600;
    left: 60px;
}

.logo-image {
  height: 70px;
  margin-top: -10px;
  display: inline-block!important;
}

@media screen and (max-width: 700px) {
  .logo-image {
    margin-top: -10px!important;
  }

  #logo span {
    font-size: 38px!important;
    top: 32px!important;
  }
}

.inline-list {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}
.inline-list li {
  float: left;
}
.container {
  max-width: 1207px;
  padding-left: 0px;
}
/* Header --------------------------------------------------------*/
.navbar-toggle {
  background-color: #2D3E50 !important;
  border-color: #444 !importan;
}
.top-info {
  /*background: #2D3E50;*/
  background-color: #403931;
}
.top-info * {
  color: #7F90A0;
}
.contact-top {
  margin: 0 -21px;
}
.contact-top li {
  font-size: 12px;
  line-height: 43px;
  padding: 0 20px;
}
.contact-top li i {
  font-size: 1.5em;
  margin-right: 10px;
}
.contact-top li a:hover {
  color: #7F90A0;
}
header {
  /*background: #34495E;*/
  background-color: #fff;
  border-bottom: 3px solid #01538A;
}
.navbar-default {
  background: none;
  border: 0;
  padding: 24px 0 26px;
}
.navbar-brand {
  padding: 0;
}

.navbar-nav {
  float: right;
  margin: 0;
}
.navbar-nav > li {
  float: left;
  font-size: 15px;
}

.navbar-nav > li > a {
  margin-top: 11px;
  padding: 7px 15px;
  margin-left: 12px;
}

/* intro --------------------------------------------------------*/
.intro-area {
  /*background: url("../img/bg-intro.jpg") repeat scroll center top transparent;*/
  background-color: #4398e9;
  /*background-image: radial-gradient(#c6c7c7, #babcb9, #8e8d8b);*/
  padding: 16px 0;
}
.intro-area * {
  color: #353535;
}
.intro-area h1 {
  font-size: 68px;
  font-weight: 700;
  margin-top: 50px;
    margin-bottom: 0px;
    color: #fff;
    text-align: left;
}

.intro-area h2 {
  font-size: 28px;
  font-weight: 700;
  margin-top: 0px;
    margin-top: 20px;
    color: #fff574;
    margin-bottom: 30px;
    text-align: left;
}
.intro-area p {
  color: #fff574;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  margin-bottom: 5px;
  margin-top: 40px;
  text-align: left;
}

.intro-right-content {
  padding-left: 10px;
  padding-right: 10px;
}

.full-height {
  height: 100vh;
}


.big-logo-size {
  height: 100px;
}

@media screen and (max-width: 700px) {
  .intro-area h1 {
    font-size: 50px;
  }

  .intro-area h2 {
    font-size: 30px;
  }
}

.navbar-default .navbar-nav > li > a {
  color: #000;
  border: 1px solid transparent;
}

.navbar-default .navbar-nav > li > a:hover {
  color: #ffb91b;
}

/*.navbar-default .navbar-nav > li > a.active ,*/
.navbar-default .navbar-nav > li > a:focus {
  border: 1px solid #ffb91b;
  color: #ffb91b;
}

.btn-session {
  background-color: #DF0000!important;
  color: #fff!important;
}

.btn {
  border-radius: 3px 3px 3px 3px;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  padding: 23px 38px;
}

.btns-group {
  /*padding-left: 5px;
  padding-right: 5px;*/
}

.btns-group .btn {
  margin-bottom: 14px;
  margin-right: 22px;
  width: 350px;
}

@media screen and (max-width: 700px) {
  .btns-group .btn {
    width: 100%!important;
  }
}
.btn-lg {
  border-radius: 3px 3px 3px 3px;
  font-family: 'Raleway', sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.33;
  padding: 23px 38px;
}
.btn.has-shadow {
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.btn-lg i {
  font-size: 20px;
}

.btn-danger {
  background-color: #C64E58;
  border-color: #C64E58;
}
.btn-danger:hover {
  background-color: #5A9FAE;
  border-color: #5A9FAE;
}

body > section {
    overflow: hidden;
    padding: 46px 0;
}
/* features --------------------------------------------------------*/
#features {
    background: none repeat scroll 0 0 #f3f3f3;
    padding: 46px 0 56px;
    position: relative;
    overflow: visible;
    padding-left: 20px;
}
#features:after {
  left: 50%;
  bottom: -50px;
  position: absolute;
  content: "";
  border: 25px solid;
  border-color: #EAEAEA transparent transparent transparent;
  display: block;
  margin-left: -13.5px;
}
#features .item {

}

#features .item h3 {
  font-size: 24px;
  font-weight: 700;
  margin: 18px 0 16px;
  text-align: center;
}
#features .item p {
  line-height: 1.7em;
}

#features .item .icon img{
  width: 20%;
  /*height: 80px;*/
  text-align: center;
}

/* suscribe --------------------------------------------------------*/

#suscribe {
  padding: 71px 0 40px;
}

.input-lg {
  height: 67px;
}
.left-inner-addon {
  position: relative;
}
.left-inner-addon input {
  padding-left: 54px;
}
.left-inner-addon i {
  color: #ADC1D1;
  font-size: 19px;
  left: 17px;
  padding: 10px 12px;
  pointer-events: none;
  position: absolute;
  top: 19px;
}
section h2 {
  font-size: 54px;
  font-weight: 700;
  color: #34495e;
}
#suscribe h2 {
  margin: 68px 0 35px;
}
.form-group {
  margin-bottom: 20px;
}
.btn-warning {
  background-color: #FFB81B;
  border-color: #FFB81B;
  color: #FFFFFF;
  -webkit-transition: background-color 0.7s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
           -moz-transition: background-color 0.7s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
            -ms-transition: background-color 0.7s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
             -o-transition: background-color 0.7s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
                transition: background-color 0.7s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
}

.form-control {
  border: 2px solid #E8E8E8;
}
.form-control:focus {
  border: 2px solid #A9DCDF;
}
.form-control::-webkit-input-placeholder {
  color: #adc1d1;
}
.form-control:-moz-placeholder {
  color: #adc1d1;
}
#suscribe .btn {
  font-size: 19px;
  margin-top: 14px;
  padding: 22px 46px;
}
.btn i {
  margin-right: 6px;
}
/* gallery --------------------------------------------------------*/
#gallery {
  padding: 32px 0 86px;
  padding-left: 20px;
}
#gallery h2 {
  margin-bottom: 48px;
}
#gallery .item {
    margin: 0 auto 20px;
    max-width: 273px;
    position: relative;
}
#gallery .item * {
  color: #FFFFFF;
}
#gallery .item .icon {
  border: 2px solid white;
  font-size: 25px;
  height: 54px;
  line-height: 30px;
  margin: 62px auto 26px;
  text-align: center;
  width: 54px;
}
#gallery .item .hover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: none;
  left: 0;
  background: #FFB91B;
}
#gallery .item .hover i {
  left: 0px;
  line-height: 35px;
  position: relative;
  text-align: center;
  top: 7px;
  width: 90px;
}
#gallery .item:hover .hover {
  display: block;
}

/* twitter-sect --------------------------------------------------------*/
#twitter-sect {
  /*background: url("../img/bg-twitter.jpg") repeat scroll center top transparent;*/
  background-color: #4398e9;
  padding: 46px 0 49px;
}
#twitter-sect .ico-twitter {
  /*background: url("../img/ico-twitter.png") repeat scroll center top transparent;*/
  display: block;
  float: left;
  height: 90px;
  margin: -12px 23px 0 4px;
  width: 90px;
}
#twitter-sect .item {

}
#twitter-sect .item * {
  color: #FFFFFF;
}
#twitter-sect .item p {
  font-size: 38px;
  overflow: hidden;
  text-align: left;
}

#twitter-sect .container {
  max-width: 1009px;
}

/* cool-things --------------------------------------------------------*/
#cool-things {
  padding: 90px 0 30px 0;
  padding-left: 20px;
}
#cool-things h2 {
  margin-bottom: 41px;
}
#cool-things .item {
  background: none repeat scroll 0 0 #F0F0F0;
  padding: 20px 29px;
  margin-bottom: 25px;
  cursor: default;
}
#cool-things .item{
  -webkit-transition: background-color 0.4s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.4s ease;
           -moz-transition: background-color 0.4s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.4s ease;
            -ms-transition: background-color 0.4s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.4s ease;
             -o-transition: background-color 0.4s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.4s ease;
                transition: background-color 0.4s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.4s ease;
}
#cool-things .item:hover {
  background: #4398e9;
  -webkit-transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
           -moz-transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
            -ms-transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
             -o-transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
                transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
        
    
}

#cool-things .item .icon {
  float: left;
  width: 76px;
  height: 76px;
  background: #4398e9;
  margin-right: 25px;
  color: white;
  font-size: 40px;
  text-align: center;
  line-height: 72px;
}
#cool-things .item:hover .icon {
  color: #FFB91B;
}
#cool-things .item.right-icon .icon {
  float: right;
  margin-left: 25px;
  margin-right: 0;
}
#cool-things .item.right-icon .cont h3 {
  text-align: right;
}
#cool-things .item.right-icon .cont p {
  text-align: right;
}
#cool-things .item:hover .cont h3 {
  color: #FFFFFF;
}
#cool-things .item:hover .cont p {
  color: #FFFFFF;
}

#cool-things .item:hover .icon {
  background: #F0F0F0;
}

#cool-things .item .icon i {

}
#cool-things .item .cont {
  overflow: hidden;
}
#cool-things .item .cont h3 {
  font-weight: 700;
  margin: 1px 0 7px;
}
#cool-things .item .cont p {
  line-height: 1.6em;
  margin: 0;
}
#cool-things .item.right-icon {

}
#cool-things .item.left-icon {

}

/* cool-things --------------------------------------------------------*/

#team {
  padding: 46px 0 102px;
  padding-left: 20px;
}

#team .item {
  padding: 34px 45px;
  background: #F0F0F0;
  /*margin: 0 15px;*/
}

#team h2 {
  margin-bottom: 33px;
}
#team .item{
  -webkit-transition: background-color 0.4s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
           -moz-transition: background-color 0.4s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
            -ms-transition: background-color 0.4s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
             -o-transition: background-color 0.4s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
                transition: background-color 0.4s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
}
#team .item:hover {
  background: #4398e9;
  -webkit-transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
           -moz-transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
            -ms-transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
             -o-transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
                transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
}
#team .item:hover * {
  color: #fff;
}

#team .item .thumb {

}
#team .item .thumb img {
  margin: 0 auto;
  height: 225px;
}
#team .item h3 {
  color: #FFB91B;
  font-weight: bold;
  margin: 14px 0 3px;
  text-transform: uppercase;
}
#team .item p {
  font-size: 14px;
  line-height: 1.65em;
}
#team .item ul {
  list-style: none;
  padding: 0;
  margin: 0;
  visibility: hidden;
}
#team .item:hover ul {
  visibility: visible;
}

#team .item ul li {
  display: inline-block;
  font-size: 21px;
}
#team .item {
  background: none repeat scroll 0 0 #F0F0F0;
  /*margin: 0 15px;*/
  padding: 34px 20px;
}

.owl-controls .owl-page, .owl-controls .owl-buttons div {
  color: #CCCCCC;
  cursor: pointer;
  display: inline-block;
  display: none;
  font-size: 26px;
}

/* newsletter --------------------------------------------------------*/
#newsletter {
  /*background: url("../img/bg-twitter.jpg") repeat scroll center top transparent;*/
  padding: 76px 0 46px;
}
#newsletter input {
  height: 62px;
}
#newsletter button {
  height: 62px;
  width: 100%;
}

#newsletter .left-inner-addon i {
  left: 16px;
  top: 16px;
}

.btn-warning:hover{
  -webkit-transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
           -moz-transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
            -ms-transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
             -o-transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
                transition: background-color 0.9s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
}
/* videos ----------------------------*/
#videos {
  padding: 96px 0 41px 0;
}
#videos h2 {
  margin-bottom: 46px;
}
#videos .carousel {
  padding: 0 104px;
}

#videos .thumb {
  float: left;
  margin-right: 20px;
}
#videos .cont {
  overflow: hidden;
}
#videos .cont {
  line-height: 1.9em;
  margin-top: 14px;
}
#videos .cont p{
  line-height: 36px;
}
#videos h3 {
  font-size: 30px;
  font-weight: 700;
  margin: -5px 0 21px;
}
.video-frame {
  border: 2px solid #EAEAEA;
  border-radius: 3px;
  padding: 10px;
}
.video iframe {
  border: 0;
}
.carousel-control.left,
.carousel-control.right {
  background-image: none;
  color: #FFB81B;
  box-shadow: none;
}
.carousel-control.left:hover,
.carousel-control.right:hover {
  color: #00A0B0
}
.carousel-control span {
  background: transparent;
}
.carousel-control.left:hover span {
  background-position: 0% 100%;
}
.carousel-control.right span {
  background-position: 100% 0;
  left: auto !important;
  right: 35px !important;
  margin-top: -34px;
}
.carousel-control.right:hover span {
  background-position: 100% 100%;
}

/* pricing ----------------------------*/

#pricing {
  padding-bottom: 118px;
}
#pricing h2 {
  margin-bottom: 40px;
}
#pricing .item {
  border: 3px solid #EAEAEA;
  border-radius: 4px 4px 4px 4px;
  max-width: 357px;
  padding: 20px 25px 44px;
  -webkit-transition: background-color 0.6s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
           -moz-transition: background-color 0.6s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
            -ms-transition: background-color 0.6s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
             -o-transition: background-color 0.6s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
                transition: background-color 0.6s ease, border .3s ease, color .3s ease, opacity .3s ease-in-out, outline 0.9s ease;
}
#pricing .item:hover {
  border-color: #A2DCDE;
}

#pricing .item:hover .btn {
  background: #00A0B0;
  border-color: #A2DCDE;
}

#pricing .item h3 {
  font-size: 29px;
  font-weight: bold;
}
#pricing .item h4 {
  color: #ADC1D1;
  font-size: 15px;
  font-weight: normal;
  margin: 12px 0 32px;
}
#pricing .item ul {
  list-style: none outside none;
  margin: 0 0 33px;
  padding: 0;
}
#pricing .item ul li {
  font-size: 16px;
  padding: 6px 0;
}
#pricing .item .btn {
  font-size: 18px;
  line-height: 11px;
  padding: 24px 28px;
}

/* Footer ----------------------------- */
.footer {
  background: #4398e9;
  /*border-top: 3px solid #599EAE;*/
  color: #fff;
}
footer * {
  color: #fff;
}
.footer .container.generic-section {
  padding: 45px 0 76px;
}
.footer .item h3 {
  color: #FFFFFF;
  font-size: 2.2em;
  font-weight: bold;
  margin-bottom: 23px;
}
.footer .item i {
  font-size: 1.2em;
}
.footer .item p {
  color: #fff;
  font-size: 13px;
  line-height: 1.75em;
  margin-bottom: 5px;
}
.footer .item p a {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}
.footer .item p a:hover {
  color: #EDB734;
}
ul.social-foot {
  margin: 0;
  padding: 0;
}
ul.social-foot li {
  display: inline-block;
  padding: 0 1%;
}
ul.social-foot li a {
  display: block;
  width: 32px;
  height: 32px;
  text-indent: -9999px; 
}

ul.social-foot li a:hover {
  background-position: 0 100% !important; 
}


ul.social-foot .tooltip .tooltip-inner {
  background: white;
  color: black;
}
ul.social-foot .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #fff;
}
.tooltip-inner{
  background: #FFB81B!important;
  color: #fff!important;
}
.tooltip-arrow{
  border-top-color: #FFB81B!important;
  border-bottom-color: #FFB81B!important;
}
.fcontainer {
  max-width: 1150px;
  position: relative;
}
/* Copy ----------------------------*/
.copy {
  position: relative;
  background: #34495E;
  padding: 36px 0;
  text-align: center;
}

.copy .totop {
  background: url("./images/totop.png") repeat scroll 0 0 transparent;
  display: block;
  height: 54px;
  left: 50%;
  margin-left: -27px;
  position: absolute;
  text-indent: -9999px;
  top: -30px;
  width: 54px;
}
.copy p {
  color: #758594;
  font-size: 12px;
  margin: 0;
  line-height: 1em;
}
.copy a {
  color: #777;
}
i[class^="icon-"] {
  height: auto;
  margin: 0 !important;
  padding: 0;
  width: auto;
}
/* animations */
.from-left {
  position: relative;
  left: -3000px;
  -webkit-transition: all .6s;
  transition: all .6s;
}
.from-right {
  position: relative;
  right: -3000px;
  -webkit-transition: all .6s;
  transition: all .6s;
}
.from-bottom {
  position: relative;
  bottom: -1000px;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.rotate {
  top: -3000px;
  opacity: 0;
  -moz-transform: rotate(-4000deg);
  -o-transform: rotate(-4000deg);
  -webkit-transform: rotate(-4000deg);
  -webkit-transition: all 2s;
  transition: all 2s;
}
.rotate-normal {
  opacity: 1;
  top: 0;
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

/* Loading ----------------------------*/
header.fixed .navbar-default {
  padding: 9px 0;
  padding-bottom: 0px;
  margin-bottom: 10px;
}
header.fixed .top-info {
  height: 0;
  overflow: hidden;
}
header.fixed {
  left: 0;
  /*position: fixed;*/
  top: 0;
  width: 100%;
  z-index: 450;
  box-shadow: 0 0 4px rgba(0,0,0,0.7);
}

.pace .pace-progress {
  background-color: #C64E58 !important;
}

.pace .pace-activity:before {
  background-color: #34495E;
  background-image: url("./images/logo.png");
  background-position: center center;
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 900;
}
.navbar {
  z-index: 500;
}

.item h4 {
  margin-top: 30px;
}

.icon 
{
  text-align: center;
}


.icon-features {
  height: 50px;
}

.para-section-highlight {
  text-align: center!important;
}

.container-carousel .item {
  margin-top: 10px!important;
}

.sos-activity-img {
  height: 150px;
  width: 100%;
  margin-top: 20px;
}

@media screen and (max-width: 700px) {
  .container-carousel .item {
    margin-left: -5px!important;
  }

  .sos-activity-text {
    padding-left: 50px;
    padding-right: 50px;
  }

  #gallery .item {
      max-width: none!important;
  }

  .sos-activity-img {
    height: 200px;
    width: 100%;
    margin-top: 20px;
  }
}

.static-page-heading {
  text-align: center;
}

.static-page-container {
  margin-bottom: 40px;
  padding-left: 15px;
  padding-right: 15px;
}


.price-page-container {
  margin-bottom: 40px;
}

.static-page-container ol {
  font-size: 14px;
  line-height: 24px;
}

.static-page-container li {
  margin-top: 10px;
  margin-bottom: 10px;
}


.float-whatsapp {
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:40px;
  background-color:#25d366;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  font-size:30px;
  box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float-whatsapp {
  margin-top:16px;
}

.whatsapp-us-btn {
  background-color: #fff!important;
    border-color: #fff!important;
    color: #000!important;
    border-radius: 10px;
    margin-top: 15px;
    font-size: 20px;
    text-align: left;
    padding-left: 25px;
    padding-right: 25px;
}

.float-right {
  float: right;
  margin-top: 5px;
}

.launch-offer-text {
    font-weight: 600;
    color: #fff!important;
    font-size: 18px;
}

.sos-activity-h {
  margin-top: -8px;
  margin-bottom: 1px;
}

.highlight-point-p {
  font-size: 18px!important;
}

.footer-social-icons {
  font-size: 32px!important;
    color: #fff;
}

.extra-css-whatsapp {
  background-color: #4398e9!important;
  color: #fff!important;
  margin-top: 60px;
}

.copyright-text {
  text-align: center;
}

.quick-links a {
  font-size: 14px;
}

.left-margin-10 {
  margin-left: 10px;
}

.big-logo-size {
  height: 120px;
}

.login-img-div {
  text-align: center;
}

.login-box {
  margin-top: 150px;
  padding: 35px 25px 20px 25px;
  text-align: left;
  padding-left: 120px;
  padding-right: 200px;
}

@media screen and (max-width: 700px) {
  .login-box {
    margin-top: 30px;
    padding: 35px 25px 20px 25px;
    text-align: left;
  }
}

.login-box h2 {
  font-size: 18px;
  color: #717171;
}

.login-box h3 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 35px;
}

.login-detail-box h2 {
  font-weight: 600;
  font-size: 20px;
  margin-top: 20px;
  color: #222222;
}

.mobile-login {
  display: block;
  width: 100%;
  border: 1px solid #C6C6C6;
  box-sizing: border-box;
  border-radius: 6px;
  height: 50px;
  padding-left: 10px;
  font-size: 16px;
}

.login-detail-box {
  padding-bottom: 30px;
  padding-left: 0px;
}

.otp-box {
  width: 100%;
}

.otp-box input {
  border: 1px solid #C6C6C6;
  box-sizing: border-box;
  border-radius: 6px;
  width: 50px!important;
  height: 50px;
  margin-right: 10px;
  font-size: 16px;
}

.otp-box span {
  display: none;
}

.otp-timer-div {
    font-size: 11px;
    margin-top: 8px;
    color: #0000c5;
}

.resend-otp-text {
  font-size: 11px;
  margin-top: 8px;
  color: #0000c5;
  cursor: pointer;
}

.partitioned-input {
  width: 2em!important;
}

.reg-input {
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #000;
  margin-top: 30px;
  margin-bottom: 30px;
}

.login-btn {
  width: 250px;
  height: 40px;
  font-size: 14px;
  margin-top: 20px;
  border: none;
  background: #717171;
  border-radius: 8px;
  font-weight: 600;
  font-size: 20px;
  color: #FFFFFF;
}

.login-page-right {
  padding-left: 40px;
  padding-top: 25vh;
}

.avatar-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 5%;
  z-index: 10;
}

.avatar-header-div {
  position: absolute;            /* remove box from the normal flow */
  right: 2%;
  top: 3%;                  /* position to the right */
}

/*
@media screen and (max-width: 700px) {
  .avatar-header-div {
    position: absolute;           
    right: 2%;
    top: 8%;                  
  }
} */

.inner-avatar-header-div {
  position: relative;
  display: inline-block;
}

.inner-avatar-header-div:hover .dropdown-content {
  display: block;
}

.inner-avatar-header-div a {
  text-decoration: none;
}

.desc {
  padding: 15px;
  text-align: center;
  font-size: 15px;
}

.static-intro {
    background: #4398e9;
    color: #fff;
    padding-top: 40px;
    text-align: center;
    padding-bottom: 20px;
}

.static-intro h1 {
  font-weight: 600;
  font-size: 50px;
}

.static-intro h2 {
  font-weight: 500;
  font-size: 30px;
  color: #fff574;
}

.static-intro h3 {
  font-weight: 500;
  font-size: 22px;
  color: #fff574;
}

.pricing-section {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
}

@media screen and (min-width: 700px) {
  .pricing-section {
    display: flex;
  }
}

.item-pricing-page {
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 15px;
  color: #000;
  padding-bottom: 120px;
  height: 100%;
  position: relative;
  margin-top: 20px;
}

.pricing-page-book-btn {
  padding-left: 0px;
  padding-right: 0px;
  position: absolute;
  bottom: 0px;
  width: 90%;
}

.pricing-page-btn-css {
  width: 100%!important;
}

.points-pricing-plan {
  margin-top: 20px;
}

.strike-it {
  text-decoration:line-through;
}

.bold-price {
  font-weight: bold;
}

.big-and-green {
  font-size: 18px;
  color: green;
}

.big-and-yellow {
  font-size: 18px;
  color: #fff574;
  font-weight: bold;
}


.banner-parent {
  background-color: #fff6c2;
  text-align: center;
  padding: 20px 20px 20px 20px;
}

.banner-parent h2 {
  color: #720000;
  text-align: center;
  font-size: 44px;
}

.banner-parent p {
  color: #000;
  font-style: italic;
  font-weight: 600;
}

.whatsapp-us-summer {
  border-color: #fff!important;
  border-radius: 10px;
  font-size: 18px;
  text-align: left;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.extra-css-whatsapp-summer {
  background-color: #4398e9!important;
  color: #fff!important;
}

.btns-group-summer {
  margin: 0 auto;
}

/* testimatonial section */

.testimonial-img {
  height: 190px;
  width: 100%;
}

#features h2 {
  text-align: center;
}

.bit-img-padding {
  padding-top: 10px;
  padding-bottom: 8px;
}

.yt-iframe {
  width: 100%;
}

@media screen and (max-width: 700px) {
  .padding-yt {
    padding-left: 0px;
    padding-right: 0px;
  }
}
